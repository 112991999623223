<template>
    <div>
        <div class='tab-content' v-if='canAccessFeature'>
            <div class='card'>
                <div class='alerts-table-header'>Alerts Table</div>
                <LoadingSpinner :showLoading="loading" />
                <AlertsTable :currentAlerts="alerts" :alertTypes="alertTypes" v-if='!loading' @deleted-alert="deletedAlertCallback" @updated-alert="updatedAlertCallback"/>
            </div>
        </div>

        <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="bodyText" @cancel-upgrade="cancelUpgrade"/>
    </div>
</template>

<script>
import AlertsTable from '../QuoteMedia/alerts/AlertsTable.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal.vue';

import AlertsService from '../../service/AlertsService';

import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import EventBus from '../../event-bus';
import {mapState} from 'vuex';
import UserUtils from '../../utilities/UserUtils';

export default {
    name: 'Alerts',
    components: {
        AlertsTable, LoadingSpinner, UpgradeToPremiumModal
    },

    computed: {
        ...mapState(['users']),

        canAccessFeature() {
            return this.users.user.features.includes('Alerts') && UserUtils.isPremiumSubscriber();
        }
    },
    data() {
        return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

            alerts: [],
            alertTypes: [],
            loading: false,
            bodyText: "To get full access to view, edit and create alerts and the other premium features below, upgrade to premium.",
        }
    },

    created() {
        EventBus.off('refresh-alerts',this.refreshAlerts);
        EventBus.on('refresh-alerts', this.refreshAlerts);
    },
    activated() {
        if (this.canAccessFeature) {

            this.loading = true;
            AlertsService.getAlertTypes().then(resp => {
                this.loading = false;
                if (resp.data.status === 'success') {
                    this.alertTypes = resp.data.types;

                    this.fetchAlerts();
                } else {
                    console.error("error getting alert types: " + resp.data.message );
                }
            })
        } else {
            this.$refs.upgradeToPremiumModal.open();
        }
    },

    methods: {
        cancelUpgrade() {
            EventBus.emit('go-back-to-sandbox');
        },

        fetchAlerts() {
            console.log("Fetching alerts");
            AlertsService.getAlerts(this.$store.state.users.user.userId).then((resp) => {
                
                if (resp.data.status === 'success') {
                    this.alerts = resp.data.alerts;
                } else {
                    console.error("error getting alerts: " + resp.data.message );
                }
                this.loading = false;
            })
        },

        refreshAlerts() {
            this.loading = true;
            this.alerts = [];
            this.fetchAlerts();
        },

        deletedAlertCallback() {
            this.loading = true;
            this.alerts = [];
            this.fetchAlerts();
        },

        updatedAlertCallback() {
            this.loading = true;
            this.alerts = [];
            this.fetchAlerts();
        }
    }

}
</script>

<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.card) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.alerts-table-header {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
    margin: 0px 0px 16px 16px;
}


</style>