<template>
    <DataTable :value="currentAlerts"  class="p-datatable-sm" :scrollable="true" scrollDirection="horizontal" dataKey="alertId" 
    :resizableColumns="true" columnResizeMode="expand" :paginator="true" :rows="8">
            <Column header="Actions" columnKey="actions">
                <template #body="slotProps">
                    <div class='action-buttons-container'>
                        <Button class='edit-button blue-button' icon='pi pi-pencil' @click='openEditAlertModal(slotProps.data)' v-if='ALERT_TYPES_WITH_VALUES.includes(slotProps.data.alertTypeId)'/>
                        <Button class='delete-button red-button__secondary' icon='pi pi-trash' @click='openDeleteAlertModal(slotProps.data)' />
                    </div>
                </template>
            </Column >
            <Column field="symbol" header="Symbol">
                <template #body="slotProps">
                    <span :class='{symbol: ![31, 32, 33, 34, 35].includes(slotProps.data.alertTypeId)}' @click='goToOverview(slotProps.data.symbol, slotProps.data.assetTypeId, slotProps.data.exchangeSymbol)'>{{slotProps.data.symbol}}</span>
                </template>
            </Column>
            <Column header="Alert Type" columnKey="alertType">
                <template #body="slotProps">
                    {{alertTypeIdProcessor(slotProps.data.alertTypeId)?.title}}
                </template>
            </Column>
            <Column header="Comparator">
                <template #body="slotProps">
                    <template v-if="ALERT_TYPES_WITH_COMPARATORS.includes(slotProps.data.alertTypeId)">
                        {{slotProps.data.comparator === 1 ? "&gt;=" : "&lt;="}}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
            </Column>
            <Column field="userValue" header="User Value" >
                <template #body="slotProps">
                    <template v-if="ALERT_TYPES_WITH_NUMERIC_INPUTS.includes(slotProps.data.alertTypeId)">
                        <template v-if="slotProps.data.alertTypeId === 3">
                            {{formatter.format(slotProps.data.userValue, '0.00')}}
                        </template>
                        <template v-else-if="ALERT_PRICE_TYPES.includes(slotProps.data.alertTypeId)">
                            {{formatter.format(slotProps.data.userValue, '$0.00')}}
                        </template>
                        <template v-else-if="slotProps.data.alertTypeId === 9">
                            {{formatter.format(slotProps.data.userValue, '0.00')}}%
                        </template>
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
            </Column>
            <Column field="enabled" header="Status">
                <template #body="slotProps">
                    <InputSwitch v-model="slotProps.data.enabled" @input="onStatusInput($event, slotProps.data)"/>
                </template>
            </Column>

    </DataTable>

    <InputAlertModal ref='inputAlertModal' :inputData="selectedAlert" @updated-alert="updatedAlertCallback"/>
    <ConfirmModal  headerText='Delete this alert?' confirmButtonLabel='Delete'  ref='confirmDeleteModal' @confirmed="deleteAlertConfirmed"/>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';

import ConfirmModal from '../../modal/ConfirmModal.vue';
import InputAlertModal from './modal/InputAlertModal.vue';

import {ALERT_TYPES_WITH_NUMERIC_INPUTS, ALERT_TYPES_WITH_COMPARATORS} from './';
import {ALERT_TYPES_WITH_VALUES, ALERT_PRICE_TYPES, ASSET_TYPES} from '../../../common/constants';
import AlertsService from '../../../service/AlertsService';
import ResearchService from '../../../service/ResearchService';
import FormattingUtils from '../../../utilities/FormattingUtils';

export default {
    name: 'AlertsTable',
    emits: ['deleted-alert', 'updated-alert'],
    components: {
        DataTable, Column, InputSwitch, ConfirmModal, InputAlertModal
    },

    props: {
        currentAlerts: {
            required: true,
            type: Array
        },
        alertTypes: {
            required: true,
            type: Array
        }
    },

    data() {
        return {
            //CONSTANTS
            ALERT_TYPES_WITH_COMPARATORS,
            ALERT_TYPES_WITH_NUMERIC_INPUTS,
            ALERT_TYPES_WITH_VALUES,
            ALERT_PRICE_TYPES,

            selectedAlert: {
                alertId: null,
                alertType: null,
                comparator: null,
                userValue: null,
                symbol: null
            },

            formatter: new FormattingUtils()
        }
    },

    methods: {
        resetSelectedAlert() {
            this.selectedAlert = {
                alertId:null,
                alertType: null,
                comparator: null,
                userValue: null,
                symbol: null
            };
        },

        alertTypeIdProcessor(id) {
            const type = this.alertTypes.filter((type) => {
                return type.id === id;
            })

            return type[0];
        },

        openEditAlertModal(alert) {
            this.resetSelectedAlert();

            this.selectedAlert.alertId = alert.alertId;
            this.selectedAlert.alertType = this.alertTypeIdProcessor(alert.alertTypeId);
            this.selectedAlert.comparator = alert.comparator;
            this.selectedAlert.userValue = alert.userValue;
            this.selectedAlert.symbol = alert.symbol;

            this.$refs.inputAlertModal.open();
        },

        openDeleteAlertModal(alert) {
            this.resetSelectedAlert();

            this.selectedAlert.alertId = alert.alertId;

            this.$refs.confirmDeleteModal.open();
        },

        onStatusInput(value, alert) {
            if (value) {
                AlertsService.enableAlert(alert.alertId).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Alert successfully enabled!', life: 1500, group: 'center' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com., life: 3000, group', group: 'center' });
                        console.log("unexpected error enabling alert "+resp.data.message);

                        alert.enabled = false;
                    }
                }).catch((error) => {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com., life: 3000, group', group: 'center' });
                    console.log("unexpected error enabling alert "+error);

                    alert.enabled = false;
                })
            } else {
                AlertsService.disableAlert(alert.alertId).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Alert successfully disabled!', life: 1500, group: 'center' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com., life: 3000, group', group: 'center' });
                        console.log("unexpected error disabling alert "+resp.data.message);

                        alert.enabled = true;
                    }
                }).catch((error) => {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com., life: 3000, group', group: 'center' });
                    console.log("unexpected error disabling alert "+error);

                    alert.enabled = true;
                })
            }
        },

        deleteAlertConfirmed() {
            AlertsService.deleteAlert(this.selectedAlert.alertId).then((resp) => {
                if (resp.data.status === 'success') {
                    this.$toast.add({ severity: 'success', summary: 'Alert successfully deleted!', life: 1500, group: 'center' });
                    this.$emit('deleted-alert');
                } else {
                    this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                    console.log("unexpected error deleting alert "+resp.data.message);
                }
            }).catch((error) => {
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                console.log("unexpected error disabling alert "+error);
            })
        },

        updatedAlertCallback() {
            this.$emit('updated-alert');
        },

        goToOverview(symbol, id, exchange) {
            console.log(exchange)
            if (id === ASSET_TYPES['EQUITY']) {
                ResearchService.setSelectedEquityFromXignite(exchange, symbol, (resp) => {
                    if (resp == 'success') {
                        this.$router.push({ path: '/equity' });
                    }
                })
            } else if (id === ASSET_TYPES['FUND']) {
                ResearchService.setSelectedFundFromXignite(exchange, symbol, (resp) => {
                    if (resp == 'success') {
                        this.$router.push({ path: '/fund' });
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-datatable ::v-deep(.p-datatable-thead > tr > th) {
    background: none;
    color: #BFBFBF;
    font-weight: normal;
    padding: 16px;

}

.p-datatable.p-datatable-sm ::v-deep(.p-datatable-tbody > tr > td) {
    color: black;
    padding: 16px;
    font-size: 16px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td .symbol {
    color: #33CC99;
    cursor: pointer;
}

.action-buttons-container {
    display: flex;
    align-items: center;
}
.action-buttons-container > ::v-deep(button) {
    margin-right: 8px;
    border-radius: 50px;
}
.action-buttons-container > ::v-deep(button:last-child) {
    margin-right: 0px;
}


</style>